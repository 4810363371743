import hero from "../../Assets/About/hero.png";
import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  hero: {
    display: "flex",
    height: "100vh",
    fontFamily: "Neue400",
  },
  leftSide: {
    background: "#DAE7DE",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  rightSide: {
    background: `url(${hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
  },
  heading: {
    color: "#4F6867",
    fontSize: "36px",
    lineHeight: "48px",
    letterSpacing: "0.015em",
  },
  image: {
    height: "100%",
    width: "100%",
  },
  innerContent: {
    width: "80%",
  },
  text: {
    fontSize: "20px",
    margin: "20px 0",
    lineHeight: "1.2",
  },

  "@media (max-width: 780px)": {
    hero: {
      flexDirection: "column-reverse",
      height: "auto",
    },
    rightSide: {
      height: "300px",
    },
    image: {
      width: "100%",
    },
    heading: {
      paddingTop: "20px",
    },
    heading2: {
      paddingTop: "20px",
    },
  },
});
const Hero = () => {
  const classes = useStyles();

  return (
    <div className={classes.hero}>
      <div className={classes.leftSide}>
        <div className={classes.innerContent}>
          <h2 className={classes.heading}>
            The world need us
            <br />
            We need the world
          </h2>
          <p className={classes.text}>
            Amid the current situation, we’re hopeful. We’re positive. We have
            the ability. We dare to envision a world where business is
            sustainability and efficiency merge together.
          </p>
          <h2 className={`${classes.heading} ${classes.heading2}`}>
            Why join hands with NEST?
          </h2>
          <p className={classes.text}>
            Global Access
            <br />
            Expertise & Experience
            <br />
            Commercial Focus
          </p>
        </div>
      </div>
      <div className={classes.rightSide}></div>
    </div>
  );
};

export default Hero;
