import { createUseStyles } from "react-jss";
import bkg from "../../Assets/Home/hero.png";
import { Link } from "react-router-dom";

const useStyles = createUseStyles({
  wrapper: {
    backgroundImage: `url(${bkg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  title: {
    color: "#4F6867",
    fontFamily: "NeueBold",
    fontSize: "48px",
    lineHeight: "125%",
    maxWidth: "281px",
  },
  desp: {
    color: "#000",
    fontFamily: "Neue400",
    fontSize: "24px",
    lineHeight: "150%",
    // width: "281px",
    letterSpacing: "0.015em",
    width: "412px",
    padding: {
      top: "21px",
      bottom: "30px",
    },
  },
  btn: {
    fontFamily: "Neue400",
    border: "none",
    padding: {
      top: "16px",
      bottom: "16px",
      right: "30px",
      left: "30px",
    },
    color: "#DAE7DE",
    borderRadius: "15px",
    backgroundColor: "#4F6867",
    cursor: "pointer",
  },
  content: {
    padding: {
      top: "123px",
      bottom: "125px",
      left: "206px",
    },
  },
  "@media (max-width: 760px)": {
    content: {
      padding: "30px 0",
      textAlign: "center",
    },
    title: {
      margin: "0 auto",
      lineHeight: "110%",
    },
    desp: {
      margin: "0 auto",
      lineHeight: "125%",
    },
  },
  "@media (max-width: 460px)": {
    title: {
      width: "90%",
    },
    desp: {
      width: "90%",
    },
  },
});

const Hero = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <p className={classes.title}>
          It’s time to switch to sustainable textiles
        </p>
        <p className={classes.desp}>
          Our actions can change the world. Join us and be part of the change.
        </p>
        <Link to="/contact">
          <button className={classes.btn}>Contact Us</button>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
