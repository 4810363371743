import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
const useStyles = createUseStyles({
  main: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderTop: "1px solid black",
  },
  innerContent: {
    width: "50%",
    "& h2": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "48px",
      lineHeight: "119.69%",
      fontFamily: "Neuebold",
    },
    "& p": {
      marginTop: "30px",
    },
    "& div": {
      fontFamily: "Neue400",
      border: "none",
      width: "150px",
      height: "60px",
      color: "#DAE7DE",
      borderRadius: "15px",
      backgroundColor: "#4F6867",
      display: "flex",
      cursor: "pointer",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      margin: {
        top: "80px",
        left: "auto",
        right: "auto",
        bottom: "0",
      },
      "& a": {
        color: "rgba(218, 231, 222, 0.9)",
        textDecoration: "none",
      },
    },
  },
  "@media (max-width: 580px)": {
    innerContent: { width: "90%" },
  },
});
const Submission = () => {
  const classes = useStyles();
  return (
    <div class={classes.main}>
      <div class={classes.innerContent}>
        <h2>Thanks for your submission!</h2>
        <p>
          Now that we know more about your company, we'll make calculations and
          get back to you shortly via email with a data report and suggestions
          on how your company can become more sustainable.
        </p>
        <div>
          <Link to="/">Home</Link>
        </div>
      </div>
    </div>
  );
};

export default Submission;
