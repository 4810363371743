import SideImage from "../../Assets/Impact/impact.png";
import { createUseStyles } from "react-jss";
import { useState } from "react";
const useStyles = createUseStyles({
  outerDiv: {
    display: "flex",
    flexFlow: "row",
    fontFamily: "Neue400",
  },
  leftSide: {
    backgroundImage: `url(${SideImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyItems: "center",
    flexDirection: "column",
    color: "#000",
    padding: "100px 100px",
    flex: "0 1 40vw",
  },
  rightSide: {
    padding: "70px 100px",
    borderTop: "1px solid #4F6867",
    flex: "0 1 60vw",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "125%",
  },
  contactus: {
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "54px",
    letterSpacing: "0.015em",
    color: "#4F6867",
  },
  textInput: {
    display: "block",
    border: "none",
    borderBottom: "1px solid #4F6867",
    width: "100%",
    padding: "15px 0 5px 0px",
    marginBottom: "30px",
    "&:focus": {
      outline: "none",
    },
  },
  leftBoxText: {
    fontSize: "18px",
    marginTop: "20px",
  },
  inputMessage: {
    fontSize: "24px",
    color: "#000",
    padding: "3px 0",
  },
  textArea: {
    width: "100%",
    borderRadius: "15px",
    padding: "20px 5px",
  },
  privacy: {
    fontSize: "12px",
    marginRight: "100px",
  },
  underForm: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  button: {
    textDecoration: "none",
    color: "#fff",
    padding: "15px 45px",
    background: "#4F6867",
    borderRadius: "15px",
    cursor: "pointer",
    height: "max-content",
  },
  emailDrop: {
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: "bold",
    color: "#000",
    textDecoration: "none",
  },
  twoInputs: {
    display: "flex",
    justifyContent: "space-evenly",
    "& div": {
      width: "100%",
      marginRight: "20px",
    },
  },
  selectDiv: {
    paddingTop: "15px",
  },
  selectOption: {
    padding: {
      top: "10px",
      left: "20px",
      right: "140px",
      bottom: "10px",
    },

    borderRadius: "15px",
    "@media (max-width: 425px)": {
      width: "100%",
      paddingRight: "14px",
    },
  },
  "@media (max-width: 970px)": {
    heading: {
      fontSize: "36px",
    },
  },
  "@media (max-width: 920px)": {
    outerDiv: {
      flexDirection: "column",
      flexFlow: "nowrap",
    },
    leftSide: {
      flex: "0",
    },
  },
  "@media (max-width: 580px)": {
    rightSide: {
      flex: "0",
      padding: "70px 60px",
    },
    underForm: {
      flexDirection: "column",
    },
    button: {
      textAlign: "center",
      marginTop: "40px",
    },
    privacy: {
      margin: "0",
    },
  },
  "@media (max-width: 520px)": {
    twoInputs: {
      flexDirection: "column",
    },
  },

  "@media (max-width: 425px)": {
    rightSide: {
      padding: "60px 30px",
    },
  },
  "@media (max-width: 375px)": {
    rightSide: {
      padding: "60px 20px",
    },
    leftSide: {
      textAlign: "center",

      padding: "60px 20px",
    },
  },
});

const ImpactVsCost = () => {
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [production, setProduction] = useState("");
  const [fabric, setFabric] = useState("");
  const [quantity, setQuantity] = useState("");
  const [error, setError] = useState("");
  const classes = useStyles();

  const validateInputs = () => {
    setError("");
    if (company == "") {
      setError("Kindly Enter Company Name");
      return;
    }
    if (
      email.search(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
      ) == -1
    ) {
      setError("Invalid Email Address");
      return;
    }
    if (production == "") {
      setError("Kindly Enter Your Production");
      return;
    }
    if (fabric == "") {
      setError("Kindly Enter a Fabric");
      return;
    }
    if (quantity == -1 || quantity == "") {
      setError("Select Quantity");
      return;
    }
  };
  return (
    <div className={classes.outerDiv}>
      <div className={classes.leftSide}>
        <h3 className={classes.heading}>Quantify Your Impact vs Cost</h3>
        <p className={classes.leftBoxText}>
          Tell us about your current production and we’ll get back to you with
          your current carbon and water footprints. Learn about the most
          sustainable alternative for your product, including how much carbon
          and water you will save and what costs you could save while trying to
          be carbon neutral.
        </p>
      </div>
      <div className={classes.rightSide}>
        <h4 style={{ color: "tomato" }}>{error}</h4>
        <div className={classes.twoInputs}>
          <div>
            <p className={classes.inputMessage}>Company Name</p>

            <input
              className={classes.textInput}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              type="text"
              placeholder="Company Name"
            />
          </div>
          <div>
            <p className={classes.inputMessage}>Email</p>

            <input
              className={classes.textInput}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="example@mail.com"
            />
          </div>
        </div>
        <div>
          <p className={classes.inputMessage}>What are you producing?</p>

          <input
            className={classes.textInput}
            value={production}
            onChange={(e) => setProduction(e.target.value)}
            type="text"
            placeholder="Shirts, sweaters, skirts, etc..."
          />
        </div>
        <div>
          <p className={classes.inputMessage}>
            What fabric are you planning on using?
          </p>

          <input
            className={classes.textInput}
            value={fabric}
            onChange={(e) => setFabric(e.target.value)}
            type="text"
            placeholder="Cotton, polyester, nylon, etc..."
          />
        </div>
        <p className={classes.inputMessage}>What quantity are you producing?</p>
        <div className={classes.selectDiv}>
          <select
            className={classes.selectOption}
            onChange={(e) => setQuantity(e.target.value)}
          >
            <option value="-1" disabled selected>
              Select a quantity
            </option>
            <option value="1-10">1-10</option>
            <option value="10-100">10-100</option>
            <option value="100-1000">100-1000</option>
            <option value="1000+">1000+</option>
          </select>
        </div>
        <div className={classes.underForm}>
          <a className={classes.button} onClick={validateInputs}>
            Send
          </a>
        </div>
      </div>
    </div>
  );
};

export default ImpactVsCost;
