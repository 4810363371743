import Hero from "../Components/Solutions/Hero";
import AllSolutions from "../Components/Solutions/AllSolutions";
import KeyIssues from "../Components/Solutions/KeyIssues";
import ProductPros from "../Components/Solutions/ProductPros";

const SolutionsPage = () => {
  return (
    <>
      <Hero />
      <AllSolutions />
      <KeyIssues />
      <ProductPros />
    </>
  );
};

export default SolutionsPage;
