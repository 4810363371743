import { createUseStyles } from "react-jss";
import HorizontalBar from "../../Helper/HorizontalBar";

const useStyles = createUseStyles({
  outBox: {
    marginTop: "100px",
    fontFamily: "Neue400",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
    color: "#4F6867",
  },
  text: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    marginBottom: "20px",
  },
});
const Scrolling = ({ heading, text, filename, id }) => {
  const classes = useStyles();
  return (
    <div className={classes.outBox} id={id}>
      <h2 className={classes.heading}>{heading}</h2>
      <p className={classes.text}>{text}</p>
      <HorizontalBar filename={filename} />
    </div>
  );
};

export default Scrolling;
