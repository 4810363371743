import { useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  textInput: {
    fontFamily: "Neue400",

    display: "block",
    border: "none",
    borderBottom: "1px solid #4F6867",
    width: "100%",
    padding: "15px 0 5px 0px",
    marginBottom: "30px",
    "&:focus": {
      outline: "none",
    },
  },
  inputMessage: {
    fontSize: "24px",
    color: "#000",
    padding: "3px 0",
    fontFamily: "NeueMed",
  },
  oneInput: {
    fontFamily: "Neue400",

    width: "50%",
    margin: "0 auto",
  },
  oneLastInput: {
    display: "flex",
    width: "70%",
    fontFamily: "NeueMed",

    justifyContent: "space-between",
    margin: "5px 0",
    "& input": {
      display: "block",
      border: "none",
      borderBottom: "1px solid #4F6867",
      width: "40%",
      padding: "15px 0 5px 0px",
      "&:focus": {
        outline: "none",
      },
    },
    "& p": {
      fontWeight: "normal",
      fontSize: "24px",

      lineHeight: "36px",
      marginLeft: "40px",
    },
  },
  "@media (max-width: 820px)": {
    oneInput: {
      width: "80%",
    },
    oneLastInput: {
      width: "80%",
      "& p": {
        fontSize: "18px",
        width: "60%",
      },
    },
    inputMessage: {
      fontSize: "18px",
    },
  },
  "@media (max-width: 580px)": {
    oneLastInput: {
      width: "100%",
    },
  },
  "@media (max-width: 440px)": {
    oneLastInput: {
      flexDirection: "column",
      "& input": {
        marginLeft: "40px",
        width: "80%",
      },
      "& p": {
        fontSize: "14px",
      },
    },
  },
  "@media (max-width: 300px)": {
    oneLastInput: {
      "& input": {
        marginLeft: "0px",
      },
      "& p": {
        marginLeft: "0px",
      },
    },
  },
});
const OneInput = ({ question, key, id }) => {
  const classes = useStyles();

  const [answer, setAnswer] = useState("");
  const [wages, setWages] = useState("");
  const [carbon, setCarbon] = useState("");
  const [water, setWater] = useState("");
  const [diversity, setDiversity] = useState("");

  return (
    <div className={classes.oneInput}>
      <p className={classes.inputMessage}>
        {id}. {question}
      </p>
      {id != "25" ? (
        <input
          className={classes.textInput}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          type="text"
          placeholder="Answer"
        />
      ) : (
        <div>
          <div className={classes.oneLastInput}>
            <p>Wages Transparency</p>
            <input
              type="text"
              placeholder="Ratio"
              value={wages}
              onChange={(e) => setWages(e.target.value)}
            />
          </div>
          <div className={classes.oneLastInput}>
            <p>Carbon footprint</p>
            <input
              type="text"
              placeholder="Ratio"
              value={carbon}
              onChange={(e) => setCarbon(e.target.value)}
            />
          </div>
          <div className={classes.oneLastInput}>
            <p>Water footprint </p>
            <input
              type="text"
              placeholder="Ratio"
              value={water}
              onChange={(e) => setWater(e.target.value)}
            />
          </div>
          <div className={classes.oneLastInput}>
            <p>Diversity</p>
            <input
              type="text"
              placeholder="Ratio"
              value={diversity}
              onChange={(e) => setDiversity(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default OneInput;
