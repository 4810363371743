import { createUseStyles } from "react-jss";
import { useState, useEffect, useContext } from "react";
import {
  ScrollMenu,
  VisibilityContext,
  Arrow,
} from "react-horizontal-scrolling-menu";

const useStyles = createUseStyles({
  oneImage: {
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  onePartner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    margin: "0px 20px",
    width: "250px",
  },
  heading: {
    fontWeight: "normal",
    fontSize: "24px",
    fontFamily: "Neue400",
    lineHeight: "150%",
    letterSpacing: "0.015em",
  },
  text: {
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "150%",
    letterSpacing: "0.015em",
  },
  "@media (max-width: 430px)": {
    onePartner: {
      width: "100px",
      marginRight: "50px",
      marginLeft: "75px",
      textAlign: "center",
      "& p": {
        fontSize: "12px",
      },
    },
    heading: {
      fontSize: "18px",
    },

    oneImage: {
      width: "auto",
      height: "100px",
      "& img": {
        width: "auto",
        maxHeight: "70px",
      },
    },
  },
});
const OnePartner = ({ image, name, text, lnk }) => {
  const visibility = useContext(VisibilityContext);

  const classes = useStyles();
  const [img, setImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const response = await import(`../../Assets/About/${image}.png`); // change relative path to suit your needs
      // console.log(response);
      setImage(response.default);
    };
    fetchImage();
  }, [image]);
  return (
    <div
      className={classes.onePartner}
      onClick={() => (lnk !== "#" ? window.open(`${lnk}`) : null)}
      style={{ cursor: "pointer" }}
    >
      <div className={classes.oneImage}>
        <img src={img} alt="" />
      </div>
      <h2 className={classes.heading}>{name}</h2>
      <p>{text}</p>
    </div>
  );
};

export default OnePartner;
