import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  wrapper: {
    padding: {
      left: "100px",
      right: "100px",
    },
  },
  title: {
    fontFamily: "NeueBold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    color: "#4F6867",
    padding: {
      top: "69px",
      bottom: "29px",
    },
  },
  solutionCards: {
    display: "flex",
    justifyContent: "space-between",
    padding: {
      bottom: "70px",
    },
  },
  solutionCard: {
    // backgroundColor: "green",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    textAlign: "center",
    width: "175px",
  },
  cardTitle: {
    padding: {
      top: "23px",
    },
    fontFamily: "Neue400",
    fontSize: "24px",
    lineHeight: "125%",
    textAlign: "center",
    fontWeight: "700",
  },
  cardDesp: {
    padding: {
      top: "18px",
    },
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "125%",
    textAlign: "center",
  },
  cardImage: {
    width: "169px",
    alignSelf: "",
  },
  "@media (max-width: 1080px)": {
    cardImage: {
      width: "130px",
    },
    cardTitle: {
      fontSize: "18px",
    },
    cardDisp: {
      fontSize: "14px",
    },
  },
  "@media (max-width: 970px)": {
    wrapper: {
      padding: "0px 20px",
    },
  },
  "@media (max-width: 740px)": {
    solutionCards: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    solutionCard: {
      margin: "20px auto",
    },
    cardImage: {
      width: "200px",
    },
  },
});

const SOLUTIONS = [
  {
    image: "fabrics.png",
    title: "Sustainable Sourcing",
    desp: "Source sustainable fabrics and materials",
  },
  {
    image: "sewing.png",
    title: "Sustainable Design & Production",
    desp: "Get the best sustainable designs produced",
  },
  {
    image: "impact.png",
    title: "Impact Measurement & Neutrality",
    desp: "Measure and reduce your environmental impact",
  },
  {
    image: "uniform.png",
    title: "Circular Uniforms",
    desp: "Eco-corporate wear with end-of-life solutions",
  },
  {
    image: "stock.png",
    title: "Data Driven Stock Optimization",
    desp: "Minimize warehouse overstocks",
  },
];

const Solutions = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <p className={classes.title}>Our Solutions</p>
      <div className={classes.solutionCards}>
        {SOLUTIONS.map((c) => (
          <div className={classes.solutionCard}>
            <img
              className={classes.cardImage}
              src={require(`../../Assets/Home/${c.image}`).default}
              alt={c.title}
            />
            <p className={classes.cardTitle}>{c.title}</p>
            <p className={classes.cardDesp}>{c.desp}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Solutions;
