import banner from "../../Assets/Efficiency/banner.png";
import { createUseStyles } from "react-jss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import OneInput from "./OneInput";
const useStyles = createUseStyles({
  banner: {
    background: `url(${banner})`,
    height: "247px",
    // width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {
    fontSize: "48px",
    lineHeight: "138.19%",
    textAlign: "center",
    color: "#FFFFFF",
    fontFamily: "Neue400",
  },
  definition: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "138.19%",
    fontFamily: "Neue400",
    width: "50%",
    display: "block",
    margin: "40px auto",
    "& span": {
      fontFamily: "NeueMed",
      fontSize: "18px",
    },
    "& p": {
      paddingTop: "10px",
    },
  },

  twoInputs: {
    display: "flex",
    justifyContent: "space-evenly",
    "& div": {
      width: "100%",
      marginRight: "20px",
    },
    fontFamily: "Neue400",
  },
  inputMessage: {
    fontSize: "24px",
    color: "#000",
    fontFamily: "NeueMed",

    padding: "3px 0",
  },
  textInput: {
    display: "block",
    border: "none",
    borderBottom: "1px solid #4F6867",
    width: "100%",
    padding: "15px 0 5px 0px",
    marginBottom: "30px",
    "&:focus": {
      outline: "none",
    },
  },
  topInputs: {
    width: "50%",
    margin: "0 auto",
  },
  button: {
    textDecoration: "none",
    color: "#fff",
    padding: "15px 45px",
    background: "#4F6867",
    borderRadius: "15px",
    fontFamily: "Neue400",
    cursor: "pointer",
    height: "max-content",
  },
  buttonDiv: {
    width: "50%",
    display: "flex",
    margin: "60px auto 100px auto",
    justifyContent: "flex-end",
  },
  "@media (max-width: 820px)": {
    definition: {
      width: "85%",
      fontSize: "16px",
      "& span": {
        fontSize: "16px",
      },
    },
    heading: { fontSize: "36px", width: "80%" },
    twoInputs: {
      flexDirection: "column",
      "& div": {
        width: "100%",
        marginRight: "0px",
      },
    },
    inputMessage: {
      fontSize: "18px",
    },
    topInputs: {
      width: "80%",
    },
  },
});
const Efficiency = () => {
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState([]);
  const classes = useStyles();
  const getData = () => {
    fetch("efficiency.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        // console.log(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className={classes.banner}>
        <h2 className={classes.heading}>Quantify Your Efficiency Level</h2>
      </div>
      <div className={classes.definition}>
        <p>
          <span>Overproduction</span> is the critical pain point of the textile
          industry. When your efficiency parameters, such as if your stock
          versus sales ratio gets worse, your <span>sustainability</span>{" "}
          parameters (carbon footprint, waste, energy consumption) get worse.
          That’s why we say that efficiency drives sustainability.
        </p>
        <p>
          {" "}
          We can help you identify holes where your business is losing and the
          planet is losing too so you can implement <span>
            best practices
          </span>{" "}
          and be more <span>profitable</span> and more sustainable.
        </p>
        <p>
          Some information that we need may be <span>confidential</span>, but we
          will treat all your data as such. However, feel free to use proxys or
          leave points you consider too sensitive to share blank. We will play
          with the rest and <span>feedback</span> you over email.
        </p>
      </div>
      <div className={classes.topInputs}>
        <div className={classes.twoInputs}>
          <div>
            <p className={classes.inputMessage}>Company Name</p>

            <input
              className={classes.textInput}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              type="text"
              placeholder="Example Inc."
            />
          </div>
          <div>
            <p className={classes.inputMessage}>Email</p>

            <input
              className={classes.textInput}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="example@mail.com"
            />
          </div>
        </div>
      </div>
      {data != null
        ? data.map((d) => (
            <OneInput key={d.id} id={d.id} question={d.question} />
          ))
        : null}

      <div className={classes.buttonDiv}>
        <Link to="/submission" className={classes.button}>
          Send
        </Link>
      </div>
    </div>
  );
};

export default Efficiency;
