import { createUseStyles } from "react-jss";
import check from "../../Assets/Solutions/check.svg";
const useStyles = createUseStyles({
  outerMain: {
    display: "flex",
    fontFamily: "Neue400",
  },
  innerLeft: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    background: "#4F6867",
    padding: "50px",
  },
  heading: {
    fontWeight: "normal",
    fontSize: "36px",
    lineHeight: "125%",
    letterSpacing: "0.015em",
    color: "#DAE7DE",
    width: "60%",
  },
  innerRight: {
    width: "100%",
    background: "#E6EBED",
    padding: "50px",
  },
  onePoint: {
    display: "flex",
    alignItems: "flex-start",
    marginLeft: "30px",
    width: "80%",
    justifyContent: "space-between",
  },
  check: {
    marginRight: "20px",
    marginTop: "25px",
  },
  rightHead: {
    marginLeft: "30px",
    fontWeight: "normal",
    fontSize: "36px",
    lineHeight: "125%",
    letterSpacing: "0.015em",
    color: "#4F6867",
  },
  innerText: {
    margin: "20px 0px",
    fontSize: "24px",
    lineHeight: "125%",
  },
  "@media (max-width: 920px)": {
    outerMain: {
      flexDirection: "column",
    },
    innerLeft: {
      textAlign: "center",
      padding: "50px 0px",
    },
    innerRight: {
      padding: "30px 0",
    },
  },
});

const ProjectPros = () => {
  const classes = useStyles();
  return (
    <div className={classes.outerMain}>
      <div className={classes.innerLeft}>
        <h3 className={classes.heading}>
          We help you design and produce any of your textile products, but in
          the most sustainable way.
        </h3>
      </div>
      <div className={classes.innerRight}>
        <h4 className={classes.rightHead}>Your product will</h4>
        <div className={classes.onePoint}>
          <img src={check} className={classes.check} alt="" />
          <p className={classes.innerText}>
            Be certified by leading sustainable textile suppliers to minimize
            the footprint of each product
          </p>
        </div>
        <div className={classes.onePoint}>
          <img src={check} className={classes.check} alt="" />
          <p className={classes.innerText}>
            Have an Environmental Impact Report, so you and your customers can
            understand the savings you created by using our products instead of
            conventional ones
          </p>
        </div>
        <div className={classes.onePoint}>
          <img src={check} className={classes.check} alt="" />
          <p className={classes.innerText}>
            Be carbon and water neutral. We offset your products' footprint and
            create circularity for the end of life for your products
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectPros;
