import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  wrapper: {},
  title: {
    fontFamily: "NeueBold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
    padding: {
      top: "70px",
      bottom: "44px",
    },
    color: "#4F6867",
  },
  impactValue: {
    fontFamily: "Neue400",
    fontSize: "45px",
    lineHeight: "68px",
    textAlign: "center",
    color: "#4F6867",
  },
  impactText: {
    fontFamily: "Neue400",
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    color: "#000",
    maxWidth: "84px",
  },
  impacts: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: {
      bottom: "98px",
    },
    maxWidth: "1138px",
    margin: "auto",
    flexWrap: "wrap",
  },
  impact: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 25px",
  },
  "@media (max-width: 460px)": {
    impacts: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
});
const IMPACTS = [
  {
    impact: "33,611KG",
    text: "Carbon Savings",
  },
  {
    impact: "1649,670 L",
    text: "Water Savings",
  },
  {
    impact: "281",
    text: "Planted Trees",
  },
  {
    impact: "268550.9L",
    text: "Water Restored",
  },
  {
    impact: "35000",
    text: "Recycled Bottles",
  },
];
const ImpactSoFar = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <p className={classes.title}>Our Impact So Far</p>
      <div className={classes.impacts}>
        {IMPACTS.map((im) => (
          <div className={classes.impact}>
            <p className={classes.impactValue}>{im.impact}</p>
            <p className={classes.impactText}>{im.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImpactSoFar;
