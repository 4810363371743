import { createUseStyles } from "react-jss";
import heroImage from "../../Assets/Solutions/hero.png";

const useStyles = createUseStyles({
  wrapper: {
    backgroundImage: `url(${heroImage})`,
    backgroundRepeat: "no-repeat",
    baclgroundSize: "cover",
    // height: "70vh",
    color: "#fff",
  },
  innerWrapper: {
    display: "flex",
    maxWidth: "80%",
    // height: "80%",
    alignItems: "end",
    margin: "0 auto",
    justifyContent: "space-between",
    paddingTop: "74px",
    paddingBottom: "95px",
    // paddingLeft: "324px",
    // paddingRight: "324px",
    color: "#DAE7DE",
  },
  heading: {
    fontFamily: "Neue400",
    fontSize: "48px",
    lineHeight: "150%",
    width: "90%",
  },
  subHeading: {
    fontFamily: "Neue400",
    fontSize: "36px",
    lineHeight: "45px",
    width: "90%",
    letterSpacing: "0.015em",
  },
  "@media (max-width: 1100px)": {
    heading: {
      fontSize: "40px",
    },
  },
  "@media (max-width: 920px)": {
    subHeading: {
      fontSize: "30px",
    },
    innerWrapper: {
      width: "90%",
    },
  },
  "@media (max-width: 810px)": {
    innerWrapper: {
      flexDirection: "column",
      textAlign: "center",
      paddingTop: "30px",
      width: "100%",
      alignItems: "center",
    },
    subHeading: {
      paddingTop: "18px",
      lineHeight: "32px",
    },
  },
  "@media (max-width: 630px)": {
    wrapper: {
      // height: "100vh",
      // backgroundPosition: "left",
      // backgroundSize: "cover",
    },
  },
  "@media (max-width: 625px)": {
    heading: {
      fontSize: "32px",
    },
    subHeading: {
      fontSize: "24px",
    },
  },
});
const Hero = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <div className={classes.heading}>
          <h2>
            We <br /> DESIGN <br /> DEVELOP <br /> DISTRIBUTE <br /> Solutions
          </h2>
        </div>
        <div className={classes.subHeading}>
          <p>
            to help you succeed on your transformational sustainable journey
          </p>
        </div>
      </div>
    </div>
  );
};
export default Hero;
