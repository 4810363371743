import Hero from "../Components/Home/Hero";
import Solutions from "../Components/Home/Solutions";
import Inovation from "../Components/Home/Inovation";
import Speaking from "../Components/Home/Speaking";
import Efficiency from "../Components/Home/Efficiency";
import CalculateImpact from "../Components/Home/CalculateImpact";
import ImpactSoFar from "../Components/Home/ImpactSoFar";
import BrandPartners from "../Components/Home/BrandPartners";

const HomePage = () => {
  return (
    <>
      <Hero />
      <Solutions />
      <Inovation />
      <Speaking />
      <Efficiency />
      <CalculateImpact />
      <ImpactSoFar />
      <BrandPartners />
    </>
  );
};

export default HomePage;
