import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  wrapper: {
    backgroundColor: "#E6EBED",
  },
  title: {
    fontFamily: "NeueBold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
    padding: {
      top: "66px",
      bottom: "57px",
    },
    color: "#4F6867",
  },
  brands: {
    display: "flex",
    maxWidth: "1066px",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: {
      bottom: "95px",
    },
  },
  brandImg: {
    margin: "10px 20px",
  },
});

const BRANDS = [
  {
    image: "brand1",
    lnk: "#",
  },
  {
    image: "brand2",
    lnk: "https://wearezurita.com/",
  },
  {
    image: "brand3",
    lnk: "#",
  },
  {
    image: "brand3",
    lnk: "#",
  },
  {
    image: "brand3",
    lnk: "#",
  },
];

const BrandPartners = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Our Brand Partners</div>
      <div className={classes.brands}>
        {BRANDS.map((brand) => (
          <div
            className={classes.brand}
            onClick={() =>
              brand.lnk !== "#" ? window.open(`${brand.lnk}`) : null
            }
            style={{
              cursor: `${brand.lnk !== "#" ? "pointer" : "unset"}`,
            }}
          >
            <img
              src={require(`../../Assets/Home/${brand.image}.png`).default}
              alt={brand.image}
              className={classes.brandImg}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandPartners;
