import { createUseStyles } from "react-jss";
import speakingImg from "../../Assets/Home/speaking.png";

const useStyles = createUseStyles({
  container: {},
  wrapper: {
    display: "flex",
    backgroundColor: "#DAE7DE",
    padding: {
      top: "90px",
      bottom: "80px",
    },
    zIndex: "2",
  },
  title: {
    fontFamily: "NeueBold",
    fontSize: "36px",
    lineHeight: "54px",
    letterSpacing: "0.015em",
    color: "#4F6867",
  },
  content: {
    padding: {
      left: "182px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    justifyContent: "flex-start",
  },
  bigBullets: {},
  bigBullet: {
    display: "flex",
  },
  bullet: {
    fontFamily: "Neue400",
    fontSize: "128px",
    // lineHeight: "193px",
    textAlign: "center",
    letterSpacing: "0.015em",
    color: "#4F6867",
    padding: {
      top: "0px",
    },
  },
  bulletTitle: {
    fontFamily: "Neue400",
    fontSize: "24px",
    lineHeight: "125%",
    color: "#4F6867",
  },
  bulletDesp: {
    padding: {
      top: "12px",
    },
  },
  bulletInfo: {
    maxWidth: "460px",
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "27px",
    color: "#000",
    padding: {
      top: "56px",
      left: "28px",
    },
  },
  image: {
    background: `url(${speakingImg})`,
    width: "100%",
    backgroundPosition: "left",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  "@media (max-width: 1200px)": {
    content: {
      padding: "0 20px 0px 50px",
    },
  },
  "@media (max-width: 820px)": {
    wrapper: {
      flexDirection: "column",
      paddingBottom: "80px",
      paddingTop: "0px",
    },
    image: {
      height: "400px",
      transform: "rotate(90deg)",
      width: "400px",
      margin: "0 auto",
    },
    content: {
      width: "auto",
    },
  },
  "@media (max-width: 660px)": {
    bigBullet: {
      flexDirection: "column",
    },
    bulletInfo: {
      padding: "0",
      width: "90%",
      margin: "0 auto",
    },

    title: {
      textAlign: "center",
      width: "100%",
    },
    bullet: {
      textAlign: "center",
      width: "100%",
    },
    bigBullets: {
      margin: "0 auto",
    },
    content: {
      padding: "20px 0 0 0",
    },
  },
  "@media (max-width: 440px)": {
    image: {
      width: "300px",
      height: "300px",
    },
  },
  "@media (max-width: 320px)": {
    image: {
      width: "220px",
      height: "220px",
    },
  },
});
const SPEAKINGS = [
  {
    bullet: "1",
    title: "Reduce Footprints",
    desp: "Our steps will always leave footprints on the planet, but those steps must be as little as possible, with the current spectrum of sustainable fabrics we can help you minimize your carbon, energy and water footprints and ultimately help you reduce your offsetting costs.",
  },
  {
    bullet: "2",
    title: "Measure Impacts",
    desp: "We help you measure your carbon and water footprints and their impact on the environment. Only measuring we can offset our footprints adequetely which is essential to achieve the maximum level of neutrality and move towards zero impact.",
  },
  {
    bullet: "3",
    title: "Clean Up Waste",
    desp: "With our innovative and circular solutions we help your company to eliminate or reduce waste, giving you a competitive advantage in the current market, where more and more customers are only interested in those brands who care.",
  },
];

const Speaking = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.image}></div>
      <div className={classes.content}>
        <p className={classes.title}>Simply speaking, we do 3 things</p>
        <div className={classes.bigBullets}>
          {SPEAKINGS.map((s) => (
            <div className={classes.bigBullet}>
              <p className={classes.bullet}>{s.bullet}</p>
              <div className={classes.bulletInfo}>
                <p className={classes.bulletTitle}>{s.title}</p>
                <p className={classes.bulletDesp}>{s.desp}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Speaking;
