import Hero from "../Components/About/Hero";
import Methodology from "../Components/About/Methodology";
import Scrolling from "../Components/About/Scrolling";
import News from "../Components/About/News";

const AboutPage = () => {
  return (
    <>
      <Hero />
      <Methodology />
      <Scrolling
        id="partners"
        heading="Our Partners"
        text="Pioneers and Leaders of the Next Generation of Sustainable Textiles"
        filename="partners.json"
      />
      <Scrolling
        id="certificates"
        heading="Our Certificates"
        text="Transparency and Verification"
        filename="certifications.json"
      />
      <News />
    </>
  );
};

export default AboutPage;
