import Efficiency from "../Components/Efficiency/Efficiency";
const EfficiencyPage = () => {
  return (
    <>
      <Efficiency />
    </>
  );
};

export default EfficiencyPage;
