import { createUseStyles } from "react-jss";
import { useState, useEffect, useContext } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import usePreventBodyScroll from "./usePreventBodyScroll";
import useDrag from "./useDrag";
import OnePartner from "../Components/About/OnePartner";

import arrow from "../Assets/About/arrow.svg";
import leftArrow from "../Assets/About/leftArrow.svg";

const useStyles = createUseStyles({
  arrowButtons: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    right: "1%",
    userSelect: "none",
    background: "transparent",
    margin: " 20px",
    border: "none",
  },
});
const HorizontalBar = ({ filename }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const [selected, setSelected] = useState([]);
  const [position, setPosition] = useState(0);

  const isItemSelected = (id) => !!selected.find((el) => el === id);
  const handleItemClick = (itemId) => () => {
    if (dragging) {
      return false;
    }
    setSelected(selected !== itemId ? itemId : "");
  };
  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };
  function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }
  const getData = () => {
    fetch(filename, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        // console.log(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }) =>
    (ev) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });
  return (
    <div>
      <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onWheel={onWheel}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
        >
          {data != null
            ? data.map((d) => (
                <OnePartner
                  itemId={d.id}
                  key={d.id}
                  image={d.image}
                  name={d.name}
                  text={d.text}
                  lnk={d.lnk}
                  onClick={handleItemClick(d.id)}
                  selected={isItemSelected(d.id)}
                />
              ))
            : null}
        </ScrollMenu>
      </div>
    </div>
  );
};

function Arrow({ children, disabled, onClick }) {
  const classes = useStyles();

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{ opacity: disabled ? "0" : "1" }}
      className={classes.arrowButtons}
    >
      {children}
    </button>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <img src={leftArrow} alt="" />
    </Arrow>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
      <img src={arrow} alt="" />
    </Arrow>
  );
}
export default HorizontalBar;
