import { createUseStyles } from "react-jss";
import inovationImg from "../../Assets/Home/inovation.png";

const useStyles = createUseStyles({
  wrapper: {},
  titleDiv: {
    backgroundColor: "#4F6867",
    fontFamily: "NeueBold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
    color: "#DAE7DE",
    "& p": {
      padding: {
        top: "74px",
        bottom: "74px",
      },
    },
  },
  content: {
    display: "flex",
  },
  desp: {
    fontFamily: "Neue400",
    fontSize: "24px",
    lineHeight: "150%",
    textAlign: "left",
    "& p": {
      padding: {
        top: "114px",
        bottom: "139px",
        left: "82px",
        right: "90px",
      },
    },
    width: "100%",
    "@media (max-width: 1024px)": {
      fontSize: "20px",

      "& p": {
        padding: {
          top: "80px",
          bottom: "80px",
          left: "24px",
          right: "24px",
        },
      },
    },
    "@media (max-width: 768px)": {
      fontSize: "18px",

      "& p": {
        padding: {
          top: "36px",
          bottom: "36px",
          left: "24px",
          right: "24px",
        },
      },
    },
  },
  image: {
    background: `url(${inovationImg})`,
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  "@media (max-width: 768px)": {
    content: {
      flexDirection: "column",
    },
    image: {
      height: "300px",
    },
  },
});

const Inovation = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.titleDiv}>
        <p>
          Through innovation,
          <br />
          any business can be sustainable
        </p>
      </div>
      <div className={classes.content}>
        <div className={classes.desp}>
          <p>
            Being efficient is being sustainable. Our mission is to minimize the
            impact that textile products have on the environment, offering
            innovative alternatives and fostering a more efficient way of
            production that ultimately will improve your business profitability.
          </p>
        </div>
        <div className={classes.image}></div>
      </div>
    </div>
  );
};

export default Inovation;
