import { createUseStyles } from "react-jss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
var selection = "";
const useStyles = createUseStyles({
  oneSolution: (direction) => ({
    flexDirection: direction,
    display: "flex",
    width: "90vw",
    padding: "40px 0px",
    justifyContent: "space-evenly",
    margin: "0 auto",
  }),
  oneImage: {
    width: "80%",
    margin: "0 auto",
  },
  twoBoxes: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
  },
  heading: {
    color: "#4F6867",
    fontSize: "48px",
    fontWeight: "normal",
    fontFamily: "Neue400",
    lineHeight: "72px",
  },
  text: {
    fontSize: "18px",
    lineHeight: "150%",
    letterSpacing: "0.015em",
    fontFamily: "Neue400",
    marginTop: "30px",
    "& a": {
      // textDecoration: "none",
      color: "black",
    },
  },
  "@media (max-width: 920px)": {
    heading: {
      fontSize: "40px",
      lineHeight: "40px",
    },
    text: {
      fontSize: "16px",
    },
  },
  "@media (max-width: 830px)": {
    oneSolution: {
      flexDirection: "column !important",
    },
    heading: {
      textAlign: "center",
      marginTop: "25px",
    },
    text: {
      textAlign: "center",
    },
  },
});

const OneSolution = ({ image, heading, text, direction }) => {
  const classes = useStyles(direction);
  const [img, setImage] = useState(null);
  useEffect(() => {
    const fetchImage = async () => {
      const response = await import(`../../Assets/Solutions/${image}.png`); // change relative path to suit your needs
      // console.log(response);
      setImage(response.default);
    };
    fetchImage();
  }, [image]);

  return (
    <div className={classes.oneSolution}>
      <div className={classes.twoBoxes}>
        <img src={img} alt={`${heading}`} className={classes.oneImage} />
      </div>
      <div className={classes.twoBoxes}>
        <div className={classes.oneImage}>
          <h3 className={classes.heading}>{heading}</h3>
          {text.map((t) => (
            <p className={classes.text}>{t}</p>
          ))}
          {heading === "Impact Measurement & Neutrality" ? (
            <p className={classes.text}>
              Learn more about our partners <Link to="/about">here.</Link>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default OneSolution;
