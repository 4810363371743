import Submission from "../Components/Submission/Submission";
const SubmissionPage = () => {
  return (
    <>
      <Submission />
    </>
  );
};

export default SubmissionPage;
