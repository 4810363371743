import { createUseStyles } from "react-jss";
import OneIssue from "./OneIssue";
import { useState, useEffect } from "react";

const useStyles = createUseStyles({
  Cards: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  heading: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "NeueBold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
    margin: "70px 0px 30px 0px",
    color: "#4F6867",
  },
  allCards: {
    marginBottom: "80px",
  },
  "@media (max-width: 950px)": {
    Cards: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "@media (max-width: 760px)": {
    heading: {
      width: "80%",
      display: "block",
      margin: "20px auto 0 auto",
    },
  },
});

const KeyIssues = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const getData = () => {
    fetch("keyIssues.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        // console.log(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className={classes.allCards}>
      <h3 className={classes.heading}>
        Our Solutions Aim to Solve 3 Key Issues
      </h3>
      <div className={classes.Cards}>
        {data != null
          ? data.map((d) => (
              <OneIssue image={d.image} heading={d.heading} text={d.text} />
            ))
          : null}
      </div>
    </div>
  );
};

export default KeyIssues;
