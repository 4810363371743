import methodology from "../../Assets/About/methodology.png";
import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  methodology: {
    textAlign: "center",
    marginTop: "60px",
    fontFamily: "Neue400",
  },
  heading: {
    fontStyle: "normal",
    fontWeight: "bold",
    marginBottom: "5px",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
    marginTop: "20px",
    color: "#4F6867",
  },
  subHeading: {
    fontSize: "24px",
    lineHeight: "36px",
  },
  text: {
    fontSize: "18px",
    lineHeight: "150%",
    width: "70%",
    margin: "0 auto",
  },
  "@media (max-width: 850px)": {
    methodology: {
      "& img": {
        width: "90%",
      },
    },
  },
  "@media (max-width: 425px)": {
    text: {
      width: "90%",
    },
  },
});
const Methodology = () => {
  const classes = useStyles();
  return (
    <div className={classes.methodology}>
      <img src={methodology} alt="" />
      <h2 className={classes.heading}>Our Methodology</h2>
      <p className={classes.subHeading}>Efficiency Drives Sustainability</p>
      <br />
      <p className={classes.text}>
        The textile industry is one of the worst polluting industries in the
        world and everyone is contributing their part in it. As NEST, we
        understand our consumer demands, Nature needs and our partner’s
        responsibilities. Through a holistic study of the grave problems in the
        textile sector, we’ve put together verified solutions to tackle them
        effectively and efficiently. Our motto “Efficiency drives
        Sustainability” is always at the forefront of our decisions, while we
        actively serve all our stakeholders with our innovative and eco-friendly
        solutions.
      </p>
      <br />
      <p className={classes.text}>Read up on our methodology here. </p>
    </div>
  );
};

export default Methodology;
