import { createUseStyles } from "react-jss";
import logoMini from "../../Assets/Shared/nestMini.png";
import certified from "../../Assets/Shared/certified.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const useStyles = createUseStyles({
  content: {
    maxWidth: "1240px",
    margin: "auto",
    padding: {
      top: "54px",
      bottom: "86px",
      left: "16px",
      right: "16px",
    },
  },
  wrapper: {
    backgroundColor: "#DAE7DE",
    borderTop: {
      width: "1px",
      color: "#000",
      style: "solid",
    },
  },
  contactTitle: {
    fontFamily: "NeueMed",
    fontSize: "18px",
    lineHeight: "150%",
  },
  contactDetails: {
    "& ul": {
      "& li": {
        listStyleType: "none",
        "& a": {
          textDecoration: "none",
          color: "black",
          fontFamily: "Neue400",
          fontSize: "14px",
          lineHeight: "150%",
        },
      },
    },
    "@media (max-width: 375px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  },
  newsLetterTitle: {
    fontFamily: "NeueMed",
    fontSize: "18px",
    lineHeight: "27px",
  },
  newsLetterDesp: {
    fontFamily: "Neue400",
    fontSize: "14px",
    lineHeight: "21px",
    padding: {
      bottom: "20px",
    },
  },
  newsLetterForm: {
    display: "flex",
    flexWrap: "wrap",
    "@media (max-width: 425px)": {
      flexDirection: "column",
    },
  },
  c2021: {
    fontFamily: "Neue400",
    fontSize: "14px",
    lineHeight: "21px",
  },
  rights: {
    fontFamily: "Neue400",
    fontSize: "14px",
    lineHeight: "21px",
  },
  emailInput: {
    backgroundColor: "transparent",
    width: "209px",
    border: "none",
    padding: "4px",
    fontFamily: "Neue400",
    fontSize: "14px",
    lineHeight: "21px",
    borderBottom: {
      color: "#000",
      width: "1px",
      style: "solid",
    },
    "&::placeholder": {
      fontFamily: "Neue400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#000",
    },
    "@media (max-width: 700px)": {
      margin: "0 auto",
      width: "100%",
    },
  },
  btn: {
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "150%",
    border: "none",
    padding: {
      top: "4px",
      bottom: "7px",
      right: "9px",
      left: "8px",
    },
    margin: {
      left: "9px",
    },
    color: "#DAE7DE",
    borderRadius: "15px",
    backgroundColor: "#4F6867",
    textDecoration: "none",
    "@media (max-width: 700px)": {
      margin: "0 auto",
      marginTop: "8px",
    },
  },
  contentA: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "@media (max-width: 700px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  contentARight: {
    "@media (max-width: 700px)": {
      textAlign: "center",
      paddingTop: "18px",
      width: "321px",
      "@media (max-width:375px)": {
        maxWidth: "280px",
      },
    },
  },
  contentALeft: {
    width: "321px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    "@media (max-width:375px)": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      maxWidth: "280px",
    },
  },
  copyrightDiv: {
    padding: {
      top: "25px",
    },
    "@media (max-width: 375px)": {
      width: "unset",
    },
  },
  privacyText: {
    padding: {
      top: "15px",
    },
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "150%",
    "@media (max-width: 700px)": {
      display: "none",
    },
  },
  privacyText2: {
    padding: {
      top: "30px",
    },
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "150%",
    display: "none",
    "@media (max-width: 700px)": {
      display: "block",
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.contentA}>
          <div className={classes.contentALeft}>
            <img src={logoMini} alt="Nest Solutions" />
            <img src={certified} alt="Brand Certified" />
            <div className={classes.contactDetails}>
              <p className={classes.contactTitle}>Contact Us</p>
              <ul>
                <li>
                  <a href="mailto:nest@wearenestsolutions.com">Email</a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nest.solutions/"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/nest-solution/"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    WeChat
                  </a>
                </li>
              </ul>
              <p className={classes.privacyText}>Privacy Policy</p>
            </div>
          </div>
          <div className={classes.contentARight}>
            <p className={classes.newsLetterTitle}>Join Our Newsletter</p>
            <p className={classes.newsLetterDesp}>
              to get access to more inspiring stories
            </p>
            <form action="#" className={classes.newsLetterForm}>
              <input
                className={classes.emailInput}
                type="email"
                placeholder="Email"
              />
              <Link to="/submission" className={classes.btn}>
                Sign Up
              </Link>
            </form>
            <p className={classes.privacyText2}>Privacy Policy</p>

            <div className={classes.copyrightDiv}>
              <p className={classes.c2021}>
                © 2021 NESTSolutions (business license [ ]).
              </p>
              <p className={classes.rights}>
                Registered in Hong Kong. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
