import { createUseStyles } from "react-jss";
import leafleft from "../../Assets/Home/leafLeft.png";
import leafRight from "../../Assets/Home/leafRight.png";

const useStyles = createUseStyles({
  wrapper: {
    position: "relative",
    backgroundColor: "white",
    textAlign: "center",
    zIndex: "-1",
  },
  title: {
    fontFamily: "NeueBold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
    color: "#4F6867",
    padding: {
      top: "73px",
      bottom: "24px",
    },
  },
  desp1: {
    padding: {
      bottom: "36px",
    },
  },
  desp2: {
    padding: {
      bottom: "24px",
    },
  },
  desps: {
    padding: {
      bottom: "62px",
      left: "338px",
      right: "338px",
    },
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "150%",
    textAlign: "center",
    letterSpacing: "0.015em",
  },
  leafleft: {
    position: "absolute",
    top: "-80px",
    left: "0",
  },
  leafRight: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
  },
  "@media (max-width: 1070px)": {
    title: {
      fontSize: "30px",
    },
  },
  "@media (max-width: 980px)": {
    desps: {
      padding: "0 138px 62px 138px",
    },
    leafleft: {
      width: "280px",
    },
    leafRight: {
      width: "180px",
      bottom: "-70px",
    },
  },
  "@media (max-width: 760px)": {
    leafleft: {
      display: "none",
    },
    desps: {
      padding: "0 0 62px 0",
      width: "90%",
      margin: "0 auto",
    },
    leafRight: {
      width: "180px",
      bottom: "-80px",
    },
  },
  "@media (max-width: 450px)": {
    title: {
      lineHeight: "36px",
    },
  },
});
const Efficiency = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <img className={classes.leafleft} src={leafleft} alt="leafy" />
      <img className={classes.leafRight} src={leafRight} alt="leafy" />
      <p className={classes.title}>Efficiency Drives Sustainability</p>
      <div className={classes.desps}>
        <p className={classes.desp1}>
          In the 21st century, sustainability is a lifestyle. A new approach to
          relations, business and operations. A way to preserve our planet. This
          new model of business is here to stay and has a decisive importance on
          our planet and our life in it. Switching to sustainable practices does
          not equate undertaking more costly liabilities. Sustainability done
          efficiently serves both the planet and profits.
        </p>
        <p className={classes.desp2}>
          We help your business include sustainability in your whole strategy
          with a seamless supply chain and a decrease in overstocks, maintenance
          and costs. Sustainable practices attract brand loyalty from consumers
          and yield recycling revenue streams. This way, efficiency in
          operations drives sustainability and vice versa.
        </p>
      </div>
    </div>
  );
};

export default Efficiency;
