import SideImage from "../../Assets/Contact/sideImage.png";
import { createUseStyles } from "react-jss";
import { useState } from "react";
const useStyles = createUseStyles({
  outerDiv: {
    display: "flex",
    flexFlow: "row",
    fontFamily: "Neue400",
  },
  leftSide: {
    backgroundImage: `url(${SideImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyItems: "center",
    flexDirection: "column",
    color: "#DAE7DE",
    padding: "100px 100px",
    flex: "0 1 40vw",
  },
  rightSide: {
    padding: "70px 100px",
    borderTop: "1px solid #4F6867",
    flex: "0 1 60vw",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "125%",
  },
  contactus: {
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "54px",
    letterSpacing: "0.015em",
    color: "#4F6867",
  },
  textInput: {
    display: "block",
    border: "none",
    borderBottom: "1px solid #4F6867",
    width: "100%",
    padding: "15px 0 5px 0px",
    margin: "30px 0",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: "black",
      fontSize: "24px",
    },
  },
  leftBoxText: {
    fontSize: "18px",
    marginTop: "20px",
  },
  placeholderTextArea: {
    fontSize: "24px",
    color: "#000",
    padding: "3px 0",
  },
  textArea: {
    width: "100%",
    borderRadius: "15px",
    padding: "20px 5px",
  },
  privacy: {
    fontSize: "12px",
    marginRight: "100px",
  },
  underForm: {
    display: "flex",
    marginTop: "30px",
  },
  button: {
    textDecoration: "none",
    color: "#fff",
    padding: "15px 45px",
    background: "#4F6867",
    borderRadius: "15px",
    cursor: "pointer",
    height: "max-content",
  },
  emailDrop: {
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: "bold",
    color: "#000",
    textDecoration: "none",
  },
  "@media (max-width: 970px)": {
    heading: {
      fontSize: "36px",
    },
  },
  "@media (max-width: 920px)": {
    outerDiv: {
      flexDirection: "column",
      flexFlow: "nowrap",
    },
    leftSide: {
      flex: "0",
    },
  },
  "@media (max-width: 580px)": {
    rightSide: {
      flex: "0",
      padding: "70px 60px",
    },
    underForm: {
      flexDirection: "column",
    },
    button: {
      textAlign: "center",
      marginTop: "40px",
    },
    privacy: {
      margin: "0",
    },
  },
});

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const classes = useStyles();

  const validateInputs = () => {
    setError("");
    if (name == "") {
      setError("Kindly Input a Name");
      return;
    }
    if (
      email.search(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
      ) == -1
    ) {
      setError("Invalid Email Address");
      return;
    }
    if (message == "") {
      setError("Kindly Enter a message");
      return;
    }
  };
  return (
    <div className={classes.outerDiv}>
      <div className={classes.leftSide}>
        <h3 className={classes.heading}>
          We are here to help you be the change.
        </h3>
        <p className={classes.leftBoxText}>
          Find out how NEST’S solutions can improve your company’s efficiency
          and sustainability parameters.
        </p>
      </div>
      <div className={classes.rightSide}>
        <h3 className={classes.contactus}>Contact Us</h3>
        <h4 style={{ color: "tomato" }}>{error}</h4>

        <input
          className={classes.textInput}
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Name"
        />
        <input
          className={classes.textInput}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Email"
        />
        <p className={classes.placeholderTextArea}>Message</p>
        <textarea
          className={classes.textArea}
          rows="10"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <div className={classes.underForm}>
          <div className={classes.leftUnderText}>
            <p className={classes.privacy}>
              By registering, you confirm that you agree to the storing and
              processing of your personal data by NEST as described in the
              Privacy policy
            </p>
          </div>
          <a className={classes.button} onClick={validateInputs}>
            Send
          </a>
        </div>
        <p className={classes.emailDrop} style={{ marginTop: "30px" }}>
          Or drop us an email at
        </p>
        <a
          className={classes.emailDrop}
          href="mailto:nest@wearenestsolutions.com"
        >
          nest@wearenestsolutions.com
        </a>
      </div>
    </div>
  );
};

export default Contact;
