import newsImage from "../../Assets/About/news.png";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  outerDiv: {
    textAlign: "center",
    margin: "100px 0",
    fontFamily: "Neue400",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
    color: "#4F6867",
    marginTop: "50px",
  },
  text: {
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "36px",
    marginTop: "10px",
  },
  button: {
    background: "#4F6867",
    borderRadius: "15px",
    textDecoration: "none",
    color: "#fff",
    padding: "10px 30px",
    margin: "50px auto 0 auto",
    display: "block",
    width: "max-content",
  },
  "@media (max-width: 850px)": {
    outerDiv: {
      width: "90%",
      margin: "100px auto",
      "& img": {
        width: "100%",
      },
    },
  },
});

const News = () => {
  const classes = useStyles();
  return (
    <div className={classes.outerDiv}>
      <img src={newsImage} alt="" />
      <h3 className={classes.heading}>News and Insights</h3>
      <p className={classes.text}>
        Stories and great things happening in the industry
      </p>
      <a href="#" className={classes.button}>
        Read
      </a>
    </div>
  );
};

export default News;
