import { createUseStyles } from "react-jss";
import bkg from "../../Assets/Home/impactBkg.png";
import { Link } from "react-router-dom";

const useStyles = createUseStyles({
  wrapper: {
    backgroundImage: `url(${bkg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  card: {
    backgroundColor: "#DAE7DE",
    padding: {
      top: "70px",
      left: "72px",
      right: "72px",
      bottom: "81px",
    },
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "320px",
  },
  title: {
    color: "#DAE7DE",
    fontFamily: "NeueBold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
    padding: {
      top: "77px",
    },
  },
  cards: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    padding: {
      top: "59px",
      bottom: "95px",
    },
  },
  cardTitle: {
    fontFamily: "Neue400",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    letterSpacing: "0.015em",
  },
  cardDesp: {
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.015em",
    padding: {
      top: "32px",
      bottom: "59px",
    },
  },
  btn: {
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "27px",
    border: "none",
    padding: {
      top: "16px",
      bottom: "17px",
      right: "37px",
      left: "35px",
    },
    color: "rgba(218, 231, 222, 0.9);",
    borderRadius: "15px",
    backgroundColor: "#4F6867",
    cursor: "pointer",
  },
  "@media (max-width: 980px)": {
    card: {
      width: "200px",
      padding: "40px",
    },
    cardTitle: {
      fontSize: "28px",
      lineHeight: "32px",
    },
    cardDesp: {
      fontSize: "16px",
    },
  },
  "@media (max-width: 640px)": {
    card: {
      width: "60%",
      padding: "40px",
      margin: "40px 0",
    },
    cards: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
});

const CalculateImpact = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <p className={classes.title}>Calculate Your Impact</p>
      <div className={classes.cards}>
        <div className={classes.card}>
          <p className={classes.cardTitle}>Quantify your impact versus cost</p>
          <p className={classes.cardDesp}>
            See how using sustainable textile alternatives could lessen your
            environmental footprint and offset savings compared with using
            traditional fabrics.
          </p>
          <Link to="/impact">
            <button className={classes.btn}>Calculate</button>
          </Link>
        </div>
        <div className={classes.card}>
          <p className={classes.cardTitle}>Quantify your efficiency level</p>
          <p className={classes.cardDesp}>
            Overproduction is the critical pain point of the textile industry.
            See how your efficiency parameters, such as your stock versus sales
            ratio, are impacting your sustainability parameters, such as carbon
            footprint, waste, and energy consumption.
          </p>
          <Link to="/efficiency">
            <button className={classes.btn}>Calculate</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CalculateImpact;
