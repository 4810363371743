import "./App.css";
import { Switch, Route } from "react-router";
import Header from "./Components/Shared/Header";
import Footer from "./Components/Shared/Footer";
import Container from "./Components/Shared/Container";
import HomePage from "./pages/HomePage";
import SolutionsPage from "./pages/SolutionsPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import Page404 from "./pages/Page404";
import ImpactVsCostPage from "./pages/ImpactVsCostPage";
import EfficiencyPage from "./pages/EfficiencyPage";
import ScrollToTop from "./Helper/ScrollToTop";
import SubmissionPage from "./pages/SubmissionPage";

function App() {
  return (
    <Container>
      <Header />
      <Switch>
        <Route path="/" exact>
          <ScrollToTop />
          <HomePage />
        </Route>
        <Route path="/solutions">
          <ScrollToTop />
          <SolutionsPage />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="/contact">
          <ScrollToTop />
          <ContactPage />
        </Route>
        <Route path="/impact">
          <ScrollToTop />
          <ImpactVsCostPage />
        </Route>
        <Route path="/efficiency">
          <ScrollToTop />
          <EfficiencyPage />
        </Route>
        <Route path="/submission">
          <ScrollToTop />
          <SubmissionPage />
        </Route>

        <Route path="/*" component={Page404} />
      </Switch>
      <Footer />
    </Container>
  );
}

export default App;
