import { createUseStyles } from "react-jss";
import { useState, useEffect } from "react";
const useStyles = createUseStyles({
  oneCard: {
    height: "600px",
    width: "400px",
    background: "#FFF6ED",
    borderRadius: "15px",
    position: "relative",
  },
  image: {
    position: "absolute",
    width: "100%",
    zIndex: "1",
  },
  headingAndGradient: {
    zIndex: "10",
    position: "absolute",
    backgroundImage:
      "linear-gradient(179.74deg, rgba(255, 246, 237, 0) 24.44%, #FFF6ED 121.19%)",
    width: "100%",
    height: "215px",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "end",
  },
  heading: {
    paddingBottom: "35px",
    fontStyle: "normal",
    fontSeight: "normal",
    fontSize: "24px",
    lineHeight: "125%",
    textAlign: "center",
    fontFamily: "Neue400",
    width: "50%",
    height: "50px",
  },
  textOne: {
    paddingTop: "250px",
    fontWeight: "bold",
    paddingBottom: "20px",
    fontStyle: "normal",
    fontFamily: "Neue400",
    fontSize: "18px",
    lineHeight: "150%",
    width: "85%",
    margin: "0 auto",
  },
  textTwo: {
    width: "85%",
    fontFamily: "Neue400",
    margin: "0 auto",
  },
  "@media (max-width: 1200px)": {
    oneCard: {
      width: "300px",
    },
    heading: {
      width: "70%",
    },
    headingAndGradient: {
      alignItems: "center",
    },
  },
  "@media (max-width: 950px)": {
    oneCard: {
      width: "500px",
      marginTop: "40px",
    },
    headingAndGradient: {
      height: "268.25px",
    },
    textOne: {
      paddingTop: "280.25px",
    },
  },
  "@media (max-width: 550px)": {
    oneCard: {
      width: "90%",
    },
    image: {
      height: "200px",
    },
    headingAndGradient: {
      // height: "4%",
    },
  },
});

const OneIssue = ({ image, heading, text }) => {
  const classes = useStyles();
  const [img, setImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const response = await import(`../../Assets/Solutions/${image}.png`); // change relative path to suit your needs
      // console.log(response);
      setImage(response.default);
    };
    fetchImage();
  }, [image]);

  return (
    <div className={classes.oneCard}>
      <div className={classes.backImage}>
        <img className={classes.image} src={img} alt="" />
        <div className={classes.headingAndGradient}>
          <h4 className={classes.heading}>{heading}</h4>
        </div>
      </div>
      <div className={classes.textOne}>{text[0]}</div>
      <div className={classes.textTwo}>{text[1]}</div>
    </div>
  );
};
export default OneIssue;
