import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  title: {
    fontFamily: "NeueBold",
    fontSize: "36px",
    lineHeight: "54px",
    textAlign: "center",
    color: "#4F6867",
    padding: {
      top: "100px",
      bottom: "100px",
    },
  },
});

const Page404 = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>Page Not Found!</h1>
    </div>
  );
};

export default Page404;
