import { useEffect, useState } from "react";
import OneSolution from "./OneSolution";
const AllSolutions = () => {
  const [data, setData] = useState([]);
  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        // console.log(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {data != null
        ? data.map((d) => {
            return (
              <OneSolution
                direction={d.direction}
                image={d.image}
                heading={d.heading}
                text={d.text}
              />
            );
          })
        : null}
    </>
  );
};

export default AllSolutions;
